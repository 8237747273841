<template>
  <v-container>
    <v-card>
      <v-img
        class="white--text align-end"
        height="100px"
        src="https://picsum.photos/1000/100"
        style="background-color: dimgrey"
      >
        <v-card-title>Witamy w fiszkach!</v-card-title>
      </v-img>
    </v-card>

    <TaskList :tasks="tasks" :loading="loading" flashcards class="mt-5" />

    <v-alert
      color="#2A3B4D"
      dark
      icon="mdi-emoticon-outline"
      dense
      v-show="!loading"
    >
      Wow! Już tu dojechałeś(aś)?! Wkrótce planujemy dodać więcej zestawów.
    </v-alert>
    <v-dialog v-model="showFlashcardsIntro" max-width="600px">
      <v-card>
        <v-card-title>Zobacz wprowadzenie</v-card-title>
        <v-card-text>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MJC5i6uMQLE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showFlashcardsIntro = false" text>Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TaskList from "../components/TaskList.vue";
import db from "@/firebase/init";

export default {
  components: { TaskList },
  data() {
    return {
      tasks: [],
      loading: true,
      showFlashcardsIntro: true,
    };
  },
  mounted() {
    this.currentWeekNumber = this.weeks.length;

    if (this.currentWeekNumber == 0) {
      this.$router.replace({ name: "Dashboard" });
    }

    if (localStorage.savedFlashcardsSession != undefined) {
      this.$router.replace({
        name: "Flashcards",
        id: JSON.parse(localStorage.savedFlashcardsSession).id,
      });
    } else {
      var docRef = db.collection("flashcards").doc("static");

      docRef
        .get()
        .then((doc) => {
          this.tasks = doc.data().list;
          this.loading = false;
        })
        .catch(() => {
          alert("Niestety nie mogliśmy pobrać listy fiszek...");
        });
    }

    if (!localStorage.viewedFlashcardsIntro == true) {
      this.showFlashcardsIntro = true;
      localStorage.viewedFlashcardsIntro = true;
    } else {
      this.showFlashcardsIntro = false;
    }
  },
  computed: {
    weeks() {
      return this.$store.state.weeks;
    },
  },
};
</script>

<style></style>
